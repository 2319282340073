<template>
  <div class="home">
    <div class="nav">
      <Nav :index="activeIndex"></Nav>
    </div>
    <div class="right_body" >
            <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
import Nav from './components/Nav.vue'
export default {
    name:'Home',
    components:{
      Nav
    },
    computed: {
      ...mapState(["activeIndex"]),
    },
    
}
</script>

<style lang="less" scoped>
.home{
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;
  display: flex;
  justify-content: left;
  .nav{
    width: 20%;
    margin-left:15%;
  }
  .right_body{
    width: 60%;
    height: 100%;
    padding: 5%;
    box-sizing: border-box;
    background-color: #fff;
  }
}
</style>