<template>
  <el-row class="tac">
    <el-col  :span="20" class="mine">
      <div class="name">{{ name }}</div>
      <div class="classname">班级：{{ classname }}</div>
      <div class="id_card">身份证号码：{{ id_card }}</div>
      <div class="bottom">
        <el-button type="primary" round @click="layout">退出登录</el-button>
      </div>
    </el-col>
  <el-col :span="20" class="nav">
    <h5></h5>
    <el-menu
      :default-active="index"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      background-color="#fff"
      text-color="#333"
      active-text-color="#409eff"
      @select="selectAction">
      <el-menu-item index="1">
        <i class="el-icon-star-off"></i>
        <span slot="title">我的缴费</span>
      </el-menu-item>
      <el-menu-item index="2">
        <i class="el-icon-star-off"></i>
        <span slot="title">我的课表</span>
      </el-menu-item>
      <el-menu-item index="3">
        <i class="el-icon-star-off"></i>
        <span slot="title">我的成绩</span>
      </el-menu-item>
      <el-menu-item index="4">
        <i class="el-icon-star-off"></i>
        <span slot="title">在线阅读</span>
      </el-menu-item>
    </el-menu>
  </el-col>
</el-row>

</template>

<script>
export default {
    props:['index'],
    data(){
      return{
        name:'xxx',
        classname:'',
        id_card:'1234567890'
      }
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      selectAction(index) {
        // 编程式导航
        if (index == 1) {
          this.$router.replace({ name: "Fee" });
        } else if (index == 2) {
          this.$router.replace({ name: "Course" });
        } else if(index == 3) {
          this.$router.replace({ name: "Result" });
        }else{
          this.$router.replace({ name: "Book" });

        }
      },
      layout(){
       
        this.$confirm('退出当前登录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          sessionStorage.removeItem('user_info');
        this.$router.push('/');
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      
      }
    },
    created(){
      const user_info=sessionStorage.getItem('user_info');
      this.name=JSON.parse(user_info).results[0].name
      this.id_card=JSON.parse(user_info).results[0].id_card
      this.classname=JSON.parse(user_info).results[0].class
    }
}
</script>

<style lang="less" scoped>
.el-menu-item, .el-submenu__title{
    height: 80px;
}
.el-menu-item {
    &:hover{
        background-color: #fff !important;
    }
}
.mine{

  height: 20vh;
  background-color: #fff;
  margin: 1vh 0;
  border-radius: 25px 25px 0 0;
}
.name{
  padding-top: 1vh;
    color: #409eff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin: 0 auto;
}
.id_card{
    color: #333;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 1vh auto;
}
.classname{
  color: #333;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 1vh auto;
}
</style> 